import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/9E7gIhOqBow">
    <SEO title="Our Greater Brother - Hebrews" />
  </Layout>
)
export default SermonPost
